import React from "react";
import SpaHero from "../../Assets/Home/massages.jpg";

const WelcomeMessage = () => {
  return (
    <div className="px-8 w-full flex flex-col md:flex-row justify-between items-center relative bg-textwhite">
      <div className="rounded-lg text-justify mt-4 md:mt-12 relative w-full md:w-1/2 lg:w-2/3">
        <div className=" w-full">
          <img
            src={SpaHero}
            className="transform w-fit rounded-lg"
            alt="Spa Hero"
          />
        </div>
      </div>

      <div className="rounded-lg w-full md:w-1/2 lg:w-1/2 xl:w-fit text-justify mt-4 md:mt-12 relative z-20">
        <p className="text-mainColor font-semibold mt-4 md:mt-7 text-[20px] md:text-[30px] px-4 md:px-8 lg:px-16">
          GET TO KNOW US
        </p>
        <h1
          className="font-sans px-4 md:px-8 lg:px-16 text-3xl md:text-4xl lg:text-6xl"
          style={{ fontFamily: "cursive", color: "#333" }}
        >
          Welcome to the <br /> Kayaspa-Bhubaneswar
        </h1>
        <p className="font-sans text-grayTextColor tracking-wide px-4 md:px-8 lg:px-16 text-[14px] md:text-[15px] text-justify">
          "Indulge in tranquility at Kayaspa Bhubaneswar. Renew your senses, relax your
          mind, and embrace serenity with our rejuvenating treatments. Your
          journey to bliss begins here."
        </p>
      </div>
    </div>
  );
};

export default WelcomeMessage;
