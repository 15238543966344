import React from "react";
import { TfiEmail } from "react-icons/tfi";
import { FaPhone } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa6";
import { AiFillInstagram } from "react-icons/ai";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <nav class="hidden md:block bg-mainColor p-4">
      <div class="container mx-auto flex flex-col lg:flex-row lg:justify-between items-center">
        <div className="flex flex-col lg:flex-row">
          <div class="flex items-center mb-2 lg:mb-0">
            <span className="p-2 bg-primaryBgColor text-mainColor rounded-full mr-2">
              <TfiEmail size={15} />
            </span>
            <span className="text-[#ffffff] tracking-wider">
              kayaspabhubaneswar@gmail.com
            </span>
          </div>

          <div class="flex items-center ml-4 mb-2 lg:mb-0">
            <span className="p-2 bg-primaryBgColor text-mainColor rounded-full mr-2">
              <FaPhone size={12} />
            </span>

            <p className="text-[#ffffff] tracking-wider">
              +91-7735791829
            </p>
          </div>
        </div>

        <div class="flex items-center">
          <div className="mr-4 text-primaryBgColor">
            <Link to="https://www.facebook.com">
              <FaFacebook size={20} />
            </Link>
          </div>

          <div className="text-primaryBgColor">
            <Link to="https://www.instagram.com">
              <AiFillInstagram size={23} />
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
