import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import cardImage1 from "../../Assets/Home/bodyScrub.jpg";
import cardImage2 from "../../Assets/Home/facialCare.jpg";
import cardImage3 from "../../Assets/Home/aromaoil.jpeg";
import BookNowModal from "../BookNow/BookNowModal";

const WelcomeCard = () => {
  const [bookNowModal, setBookNowModal] = useState(false);
  return (
    <div>
      <BookNowModal
        bookNowModal={bookNowModal}
        setBookNowModal={setBookNowModal}
      />
      <div
        class="flex items-center bg-textwhite w-full min-h-screen rounded-md"
        style={{ fontFamily: "serif" }}
      >
        <div class="container ml-auto mr-auto flex flex-wrap items-start justify-evenly rounded-md">
          <div class="w-full pl-5 lg:pl-2 mb-4 mt-4 text-center">
            <h1 class="text-3xl lg:text-4xl text-black font-extrabold">
              WHAT WE ARE OFFERING
            </h1>
          </div>

          <div class="w-full shadow-2xl md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
            <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
              <figure class="mb-2">
                <img
                  src={cardImage2}
                  alt=""
                  class="h-64 w-64 rounded-full ml-auto mr-auto"
                />
              </figure>
              <div class="rounded-lg p-9 space-y-4 text-center bg-primaryBgColor flex flex-col">
                <div>
                  <h5 class="text-black text-2xl font-bold leading-none mb-3">
                    FACIAL CARE
                  </h5>
                  <span class="text-base text-[#afa59d] leading-none">
                    "Revitalize your skin with our luxurious facials at.
                    Experience a radiant glow as our skilled therapists pamper
                    you with the finest skincare rituals. Unveil a refreshed and
                    luminous complexion at every visit."
                  </span>
                </div>
                <div class="flex justify-center items-center">
                  <button
                    onClick={() => setBookNowModal(true)}
                    class="rounded-full bg-mainColor font-bold text-white hover:bg-btnHoverColor hover:shadow-xl focus:outline-none flex justify-center items-center transition duration-300"
                  >
                    BOOK NOW &nbsp;
                    <FaArrowRight size={15} />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full shadow-2xl md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
            <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
              <figure class="mb-2">
                <img
                  src={cardImage1}
                  alt=""
                  class="h-64 w-64 rounded-full ml-auto mr-auto"
                />
              </figure>
              <div class="rounded-lg p-9 space-y-4 text-center bg-primaryBgColor flex flex-col">
                <div>
                  <h5 class="text-black text-2xl font-bold leading-none mb-3">
                    BODY SCRUBS
                  </h5>
                  <span class="text-base text-[#afa59d] leading-none">
                    "Renew your skin's radiance with our rejuvenating body
                    scrubs at. Let our expert therapists exfoliate
                    and revitalize your skin, leaving it silky-smooth and
                    glowing."
                  </span>
                </div>
                <div class="flex justify-center items-center">
                  <button
                    onClick={() => setBookNowModal(true)}
                    class="rounded-full bg-mainColor font-bold text-white hover:bg-btnHoverColor hover:shadow-xl focus:outline-none flex justify-center items-center transition duration-300"
                  >
                    BOOK NOW &nbsp;
                    <FaArrowRight size={15} />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full shadow-2xl md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
            <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
              <figure class="mb-2">
                <img
                  src={cardImage3}
                  alt=""
                  class="h-64 w-64 rounded-full ml-auto mr-auto"
                />
              </figure>
              <div class="rounded-lg p-9 space-y-4 text-center bg-primaryBgColor flex flex-col">
                <div>
                  <h5 class="text-black text-2xl font-bold leading-none  mb-3">
                    AROMA OIL
                  </h5>
                  <span class="text-base text-[#afa59d] leading-none">
                    "Embark on a sensory journey with our aromatic oil massages.
                    Indulge in the perfect blend of soothing scents and expert
                    touch, as our therapists transport you to a state of pure
                    tranquility."
                  </span>
                </div>
                <div class="flex justify-center items-center">
                  <button
                    onClick={() => setBookNowModal(true)}
                    class="rounded-full bg-mainColor font-bold text-white hover:bg-btnHoverColor hover:shadow-xl focus:outline-none flex justify-center items-center transition duration-300"
                  >
                    BOOK NOW &nbsp;
                    <FaArrowRight size={15} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeCard;
