import React from "react";
import { RiStarSFill } from "react-icons/ri";
import jessica from "../../Assets/Home/jessicabrown.jpg";
import edna from "../../Assets/Home/ednaMarxten.jpg";
import mike from "../../Assets/Home/Mikehardson.jpg";
import SpaCard from "./SpaCard";

const CustomerReviews = () => {
  const ratings = [
    {
      "name": "Neha Kumar",
      "pic": edna,
      "stars": 5,
      "type": "Customer",
      "review": "The ambiance is calming, and the staff is truly skilled."
    },
    {
      "name": "Sumant Ray",
      "pic": mike,
      "stars": 3,
      "type": "Customer",
      "review": "From hydrotherapy to body scrubs, each treatment is a sensory delight."
    },
    {
      "name": "Ritushree",
      "pic": jessica,
      "stars": 5,
      "type": "Customer",
      "review": "A sanctuary of serenity. The massages are pure bliss."
    }
  ];

  return (
    <div className="flex text-center flex-col bg-primaryBgColor pb-12 px-8">
      <div class="w-full text-center">
        <h1 class="text-3xl lg:text-4xl text-black font-extrabold">
          Customer Reviews
        </h1>
      </div>

      <div className="flex flex-col  gap-4 space-y-6 md:space-y-0 md:flex-row md:justify-evenly">
        {
          ratings?.map((data, i) => (
            <div className="p-4 relative flex flex-col text-center md:text-left text-gray-700 bg-white shadow-xl bg-clip-border rounded-xl md:w-96">
              <p className="text-justify block font-sans text-lg font-normal text-reviewColor antialiased leading-relaxed text-inherit">
                {data?.review}
              </p>

              <div className="flex justify-center items-center space-x-3 mt-2">
                <div>
                  <img
                    src={data?.pic}
                    alt="imgs"
                    className="rounded-full w-16 h-16" />
                </div>

                <div className="text-center md:text-left">
                  <p className="text-xl">
                    {data?.name}
                  </p>

                  <p>
                    {data?.type}
                  </p>
                </div>
              </div>

              <div className="my-2 flex justify-center space-x-2 text-xl antialiased font-semibold leading-snug text-blue-gray-900">
                {[...Array(5)].map((_, index) => (
                  index < data.stars ? (
                    <RiStarSFill key={index} size={30} color="#facc15" /> // Dynamic star color for filled stars
                  ) : (
                    <RiStarSFill key={index} size={30} color="#d1d5db" /> // Different color for unfilled stars
                  )
                ))}

                {/* {[...Array(5)].map((_, index) => (
                  <RiStarSFill key={index} size={30} color="#456d37" />
                ))} */}
              </div>
            </div>
          ))
        }



        {/* <div className="p-4 relative flex flex-col text-center md:text-left text-gray-700 bg-white shadow-xl bg-clip-border rounded-xl md:w-96">
          <p className="text-justify block font-sans text-lg font-normal text-reviewColor antialiased leading-relaxed text-inherit">
            "From hydrotherapy to body scrubs, each treatment is a sensory delight."
          </p>

          <div className="flex justify-center items-center space-x-3 mt-2">
            <div>
              <img src={edna} alt="" className="rounded-full w-16 h-16" />
            </div>
            <div className="text-center md:text-left">
              <p className="text-xl">Edna Marxten</p>
              <p>Customer</p>
            </div>
          </div>

          <div className="my-2 flex justify-center space-x-2 text-xl antialiased font-semibold leading-snug text-blue-gray-900">
            {[...Array(5)].map((_, index) => (
              <RiStarSFill key={index} size={30} color="#456d37" />
            ))}
          </div>
        </div> */}

        {/* <div className="p-4 relative flex flex-col text-center md:text-left text-gray-700 bg-white shadow-xl bg-clip-border rounded-xl md:w-96">
          <p className="text-justify block font-sans text-lg font-normal text-reviewColor antialiased leading-relaxed text-inherit">
            "The ambiance is calming, and the staff is truly skilled."
          </p>

          <div className="flex justify-center items-center space-x-3 mt-2">
            <div>
              <img src={mike} alt="" className="rounded-full w-16 h-16" />
            </div>
            <div className="text-center md:text-left">
              <p className="text-xl">Mike Hardson</p>
              <p>Customer</p>
            </div>
          </div>

          <div className="my-2 flex justify-center space-x-2 text-xl antialiased font-semibold leading-snug text-blue-gray-900">
            {[...Array(5)].map((_, index) => (
              <RiStarSFill key={index} size={30} color="#456d37" />
            ))}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default CustomerReviews;
