import React, { useState } from "react";
import TrueSplander from "../../Assets/Home/new&articles1.jpeg";
import SpaDay from "../../Assets/Home/news&articles2.jpeg";
import WinterSun from "../../Assets/Home/news&articles3.jpeg";
import { FaArrowRight } from "react-icons/fa";
import BookNowModal from "../BookNow/BookNowModal";

const LatestNews = () => {
  const [bookNowModal, setBookNowModal] = useState(false);
  return (
    <div>
      <BookNowModal
        bookNowModal={bookNowModal}
        setBookNowModal={setBookNowModal}
      />
      <div
        class="flex items-center bg-textwhite w-full min-h-screen"
        style={{ fontFamily: "serif" }}
      >
        <div class="container ml-auto mr-auto flex flex-wrap items-start justify-evenly">
          <div class="w-full pl-5 lg:pl-2 mb-4 mt-4 text-center">
            <h1 class="text-3xl lg:text-4xl text-ttextBlack font-extrabold">
              Latest News & Articles
            </h1>
          </div>
          <div class="w-full shadow-2xl md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
            <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
              <figure class="mb-2">
                <img src={TrueSplander} alt="" class="h-64 ml-auto mr-auto" />
              </figure>
              <div class="rounded-lg p-9 space-y-4 text-center bg-primaryBgColor flex flex-col">
                <div>
                  <h5 class="text-black text-2xl font-bold mb-3 leading-none">
                    Best place of <br /> true splander
                  </h5>
                  <span class="text-base text-black leading-none">
                    "Embark on a journey of discovery and true splendor. Indulge
                    in unparalleled luxury, where each treatment is a
                    celebration of well-being. Your path to rejuvenation
                    awaits."
                  </span>
                </div>
                <div class="flex justify-center items-center">
                  <button
                    href="javascript:;"
                    onClick={() => setBookNowModal(true)}
                    class="rounded-full bg-mainColor font-bold text-white hover:bg-btnHoverColor hover:shadow-xl focus:outline-none flex justify-center items-center transition duration-300"
                  >
                    BOOK NOW &nbsp;
                    <FaArrowRight size={15} />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full shadow-2xl md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
            <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
              <figure class="mb-2">
                <img src={SpaDay} alt="" class="h-64 ml-auto mr-auto" />
              </figure>
              <div class="rounded-lg p-9 space-y-4 text-center bg-primaryBgColor flex flex-col">
                <div>
                  <h5 class="text-ttextBlack mb-3 text-2xl font-bold leading-none">
                    Top 6 tips for choosing a day spa
                  </h5>
                  <span class="text-base text-black leading-none">
                    Clarify your objectives for relaxation or skincare.
                    <br />
                    Choose reputable spas with positive reviews.
                    <br />
                    Select therapies aligning with your goals.
                  </span>
                </div>
                <div class="flex justify-center items-center">
                  <button
                    href="javascript:;"
                    onClick={() => setBookNowModal(true)}
                    class="rounded-full bg-mainColor font-bold text-white hover:bg-btnHoverColor hover:shadow-xl focus:outline-none flex justify-center items-center transition duration-300"
                  >
                    BOOK NOW &nbsp;
                    <FaArrowRight size={15} />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full shadow-2xl md:w-1/2 lg:w-1/4 pl-5 pr-5 mb-5 lg:pl-2 lg:pr-2">
            <div class="bg-white rounded-lg m-h-64 p-2 transform hover:translate-y-2 hover:shadow-xl transition duration-300">
              <figure class="mb-2">
                <img src={WinterSun} alt="" class="h-64 ml-auto mr-auto" />
              </figure>
              <div class="rounded-lg p-9 space-y-4 text-center bg-primaryBgColor flex flex-col">
                <div>
                  <h5 class="text-ttextBlack mb-3 text-2xl font-bold leading-none">
                    Best ocean islands for winter sun
                  </h5>
                  <span class="text-base text-black leading-none">
                    Unwind in the gentle warmth, basking in sunny serenity.
                    <br /> 
                    Scenic landscapes adorned in winter hues.
                    <br />
                    Engage in winter under the comforting sun.
                  </span>
                </div>
                <div class="flex justify-center items-center">
                  <button
                    href="javascript:;"
                    onClick={() => setBookNowModal(true)}
                    class="rounded-full bg-mainColor font-bold text-white hover:bg-btnHoverColor hover:shadow-xl focus:outline-none flex justify-center items-center transition duration-300"
                  >
                    BOOK NOW &nbsp;
                    <FaArrowRight size={15} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestNews;
