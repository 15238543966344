import React from 'react';
import heroImg from "../../src/Assets/Gallery/hdr-3.png";
import images from "../Components/Gallery/images.json";

const Gallery = () => {
    document.title = 'Spa | Gallery';
    const galleryImages = images?.images;
    return (
        <div className="flex-grow relative">
            <React.Fragment>
                <div className="flex-grow relative">
                    <div className="relative">
                        <img
                            className="w-full h-auto"
                            src={heroImg}
                            alt="Tourimg"
                        />
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-mainColor text-4xl font-bold tracking-wider">
                            Our Gallery
                        </div>
                    </div>
                </div>
            </React.Fragment>

            {/* <div className="relative">
                <img
                    className="max-w-96 h-1/2"
                    src={heroImg}
                    alt="gallery"
                />
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-4xl font-semibold">
                    Our Gallery
                </div>
            </div> */}

            <div className="container mx-auto px-4">
                <section className="py-8 px-4">
                    <div className="flex flex-wrap">
                        {galleryImages?.img_row_1.map((img, k) => (
                            <div key={k} className="md:w-1/2 px-4 mb-8 md:mb-0">
                                <img className="rounded shadow-md" src={img} alt={`1st-img-row-${k}`} />
                            </div>
                        ))}
                    </div>
                </section>

                <section className="px-4">
                    <div className="flex flex-wrap">
                        {galleryImages?.img_row_2?.map((img, l) => (
                            <div className="md:w-1/3 px-4 mb-8" key={l}>
                                <img className="rounded shadow-md" src={img} alt={`2nd-img-row-${l}`} />
                            </div>
                        ))}
                    </div>
                </section>

                <section className="pb-8 px-4">
                    <div className="flex flex-wrap">
                        {galleryImages?.img_row_3?.map((img, m) => (
                            <div className="md:w-1/4 px-4 mb-8" key={m}>
                                <img className="rounded shadow-md h-40 w-64 object-cover" src={img} alt={`3rd-img-row-${m}`} />
                            </div>
                        ))}
                    </div>
                </section>
            </div>
        </div>
    );
};

export default Gallery;
