import React from "react";
import HomeBanner1 from "../../Assets/Carousel/crsl-1.webp";
import HomeBanner2 from "../../Assets/Carousel/crsl-2.webp";
import HomeBanner3 from "../../Assets/Carousel/crsl-3.webp";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import "normalize.css/normalize.css";
import "./slider-animations.css";
import "./styles.css";

const Carousel = () => {
  const content = [
    {
      title: "BangKok Spa welcomes you for achieving a Radiant and Confident glow.",
      image: HomeBanner1,
      user: "Luan Gjokaj",
      userProfile: "https://i.imgur.com/JSW6mEk.png",
    },
    {
      title: "Turn off your mind, relax and flow downstream.",
      image: HomeBanner2,
      user: "Erich Behrens",
      userProfile: "https://i.imgur.com/0Clfnu7.png",
    },
    {
      title: "Nothing makes a woman more beautiful than the belief that she is beautiful.",
      image: HomeBanner3,
      user: "Bruno Vizovskyy",
      userProfile: "https://i.imgur.com/4KeKvtH.png",
    },
  ];

  return (
    <React.Fragment>
      <Slider>
        {content.map((item, index) => (
          <div
            key={index}
            className="slider-content"
            style={{
              background: `url('${item.image}') no-repeat center center`,
            }}
          >
            <div className="inner">
              <h1>
                {item.title}
              </h1>
            </div>
          </div>
        ))}
      </Slider>
    </React.Fragment>
  );
};

export default Carousel;
