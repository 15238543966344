import React from "react";
import Carousel from "../Components/Carousel/Carousel";
import WelcomeMessage from "../Components/Home/WelcomeMessage";
import WelcomeCard from "../Components/Home/WelcomeCard";
import WhatElse from "../Components/Home/WhatElse";
import YearsExperience from "../Components/Home/YearsExperience";
import HowItWorks from "../Components/Home/HowItWorks";
import FollowInInsta from "../Components/Home/FollowInInsta";
import BookNow from "../Components/Home/BookNow";
import LatestNews from "../Components/Home/LatestNews";
import CustomerReviews from "../Components/Home/CustomerReviews";
import Sliders from "../Components/Carousel/Sliders";

const Home = () => {
  return (
    <React.Fragment>
      {/* <Carousel /> */}
      <Sliders />
      <WelcomeMessage />
      <WelcomeCard />
      <WhatElse />
      {/* <YearsExperience /> */}
      <HowItWorks />
      <CustomerReviews />
      <FollowInInsta />
      <BookNow />
      <LatestNews />
    </React.Fragment>
  );
};

export default Home;
