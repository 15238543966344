import React, { useState } from "react";
import whatelseBg from "../../Assets/Home/beautiful-spa-pic.webp";
import spaIcon from "../../Assets/Home/image-icon.png";
import BookNowModal from "../BookNow/BookNowModal";

const BookNow = () => {
  const [bookNowModal, setBookNowModal] = useState(false);
  return (
    <div
      className="bg-cover p-10 flex flex-col lg:flex-col justify-center items-center relative"
      style={{ backgroundImage: `url(${whatelseBg})` }}
    >
      <BookNowModal
        bookNowModal={bookNowModal}
        setBookNowModal={setBookNowModal}
      />
      <div className="flex justify-center items-center gap-8 text-center lg:text-left lg:w-1/2">
        <img
          className=""
          src={spaIcon}
          alt="Spa Icon" />

        <h1 className="text-btnHoverColor text-3xl lg:text-5xl font-serif leading-snug tracking-wider mb-6">
          Our Spa Center is the True Splendor
        </h1>
      </div>

      <div className="tracking-wider text-center mt-6 text-xl text-mainColor">
        Immerse yourself in the harmony of our spa experience, where every treatment is a promise of renewal for your soul. Here, the stresses of life dissolve in the hands of our expert therapists, leaving you revitalized and glowing. Embrace this sanctuary where luxury meets wellness, and step into a world where your comfort is our command. Let us guide you on a path to total relaxation, where each moment is tailored to your peace, health, and vitality. Welcome to your spa retreat, where every breath leads you closer to your purest state of bliss.
      </div>
    </div>
  );
};

export default BookNow;
