import React from 'react'
import { Link } from 'react-router-dom'

const ErrorPage = () => {
  return (
    <div>

      <div>
        <div class="bg-gradient-to-t from-[#dc958d] to-[#fdf4f5]">
          <div class="w-9/12 m-auto py-4 min-h-screen flex items-center justify-center">
            <div class="bg-white shadow overflow-hidden sm:rounded-lg pb-4">

              <div class="border-t border-gray-200 text-center">
                <div class="text-9xl font-bold text-[#bd7d75] mt-10 animate-bounce">404</div>
                <div class="text-6xl font-bold text-red-700 py-4">Oops!</div>
                <div class="text-5xl font-bold mb-8 animate-pulse">Page not found</div>

                <p class="text-2xl pb-8 px-12 font-medium">The page you are looking for does not exist.</p>
                <button class="bg-gradient-to-b from-[#dc958d] to-[#fdf4f5] hover:bg-gray-600 text-black font-extrabold px-6 py-3 rounded-md mr-6">
                 <Link to="/">GO HOME</Link>
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>


    </div>
  )
}

export default ErrorPage