import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";
import HomeBanner1 from "../../Assets/Carousel/crsl-1.webp";
import HomeBanner2 from "../../Assets/Carousel/crsl-2.webp";
import HomeBanner3 from "../../Assets/Carousel/crsl-3.webp";
import { IoArrowUndo } from "react-icons/io5";
import { IoArrowRedoSharp } from "react-icons/io5";
import { RiArrowLeftDoubleFill, RiArrowRightDoubleFill } from 'react-icons/ri';

const Sliders = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [leftButtonHover, setLeftButtonHover] = useState(false);
    const [rightButtonHover, setRightButtonHover] = useState(false);

    const leftHandleHoverStart = (side) => {
        setLeftButtonHover(true);
    };

    const leftHandleHoverEnd = (side) => {
        setLeftButtonHover(false);
    };

    const rightHandleHoverStart = (side) => {
        setRightButtonHover(true);
    };

    const rightHandleHoverEnd = (side) => {
        setRightButtonHover(false);
    };

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const goToSlide = (index) => {
        setCurrentIndex(index);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            nextSlide();
        }, 5000);

        return () => clearInterval(intervalId);
    }, [currentIndex]);

    const images = [
        {
            src: HomeBanner1,
            header: "Indulge in tranquility, where the soothing touch of serenity meets the essence of relaxation",
            text: "Let the whispers of calm fill your soul as you surrender to tranquility's gentle embrace."
        },
        {
            src: HomeBanner2,
            header: "Escape the ordinary, embrace the extraordinary. Rejuvenate your senses in a haven of peace and pampering",
            text: "Pampering redefined – immerse yourself in a haven where peace and rejuvenation intertwine seamlessly."
        },
        {
            src: HomeBanner3,
            header: "Discover a sanctuary for your mind, body, and soul. Immerse yourself in blissful moments of pure indulgence",
            text: "Let the symphony of blissful moments resonate through your being as you embrace pure indulgence."
        },
    ];

    return (
        <React.Fragment>
            <div className="relative w-full md:h-[500px] h-[400px] overflow-hidden">
                <div
                    className="flex transition-transform duration-300 ease-in-out transform"
                    style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                    {images.map((image, index) => (
                        <motion.div
                            key={index}
                            className={`w-full flex-shrink-0 relative ${index === currentIndex ? 'opacity-100' : 'opacity-0'}`}
                            initial={{ x: "-100%" }}
                            animate={{ x: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <img
                                src={image.src}
                                alt={`Slide ${index + 1}`}
                                className="w-full md:h-[500px] h-[400px] object-cover"
                            />
                            {index === currentIndex && (
                                <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center text-white text-center p-4">
                                    <motion.h2
                                        initial={{ opacity: 0, y: -50 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.9 }}
                                        className="lg:text-[40px] md:text-[30px] text-20px font-bold xl:w-[45%] lg:w-[60%] md:w-[80%] m-auto tracking-wider text-mainColor">
                                        {image.header}
                                    </motion.h2>

                                    <motion.p
                                        initial={{ opacity: 0, y: 50 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 1.2 }}
                                        className='md:w-[70%] w-[80%] m-auto md:mt-[-80px] mt-[-40px] md:text-[16px] text-[14px] tracking-wider leading-6 font-bold text-primaryBgColor'>
                                        {image.text}
                                    </motion.p>
                                </div>
                            )}
                        </motion.div>
                    ))}
                </div>

                <button
                    onClick={prevSlide}
                    className="absolute bg-transparent hover:bg-primaryBgColor top-1/2 left-2 transform -translate-y-1/2 focus:outline-none text-white animate-pulse">
                    <RiArrowLeftDoubleFill size={48} />
                </button>

                <button
                    onClick={nextSlide}
                    className="absolute bg-transparent hover:bg-primaryBgColor top-1/2 right-2 transform -translate-y-1/2 focus:outline-none text-white animate-pulse">
                    <RiArrowRightDoubleFill size={48} />
                </button>

                <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-2">
                    {images.map((_, index) => (
                        <button
                            key={index}
                            onClick={() => goToSlide(index)}
                            className={`h-1 w-1  rounded-full focus:outline-none ${currentIndex === index ? 'bg-mainColor' : 'bg-primaryBgColor'}`}
                        />
                    ))}
                </div>
            </div>
        </React.Fragment >
    );
}

export default Sliders;
