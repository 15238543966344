import React from "react";
import heroImg from "../../Assets/Contact/headerContact.png";
import { HiMiniBuildingOffice2 } from "react-icons/hi2";
import { IoMdCall } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { RiWhatsappFill } from "react-icons/ri";

const ContactDesign = () => {
  return (
    <div className="bg-white">
      <React.Fragment>
        <div className="flex-grow relative">
          <div className="relative">
            <img className="w-full h-auto" src={heroImg} alt="Tourimg" />
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-mainColor text-4xl font-bold tracking-wider">
              Contact
            </div>
          </div>
        </div>
      </React.Fragment>

      <React.Fragment>
        <div className="flex flex-wrap justify-center items-center gap-4 mt-4 mb-4">
          <div className="bg-primaryBgColor h-44 w-56 p-4 m-4 flex flex-col items-center justify-center rounded-md transition-transform transform hover:scale-105">
            <HiMiniBuildingOffice2 size={50} color='#30aadd' />

            <div className="text-mainColor text-sm font-extrabold text-center tracking-wider my-4">
              OUR BRANCH
            </div>

            <div className="text-mainColor text-xs text-center font-semibold tracking-wide">
              Infront of BMC Bhawani Mall, C/41, Saheed Nagar, Bhubaneswar, Odisha 751007
            </div>
          </div>

          <div className="bg-primaryBgColor h-44 w-56 p-4 m-4 flex flex-col items-center justify-center rounded-md transition-transform transform hover:scale-105">
            <MdEmail size={50} color='#ea4335' />

            <div className="text-mainColor text-sm font-extrabold text-center tracking-wider my-4">
              EMAIL
            </div>

            <div className="text-mainColor text-xs text-center font-semibold tracking-wide">
              kayaspabhubaneswar@gmail.com
            </div>
          </div>

          <div className="bg-primaryBgColor h-44 w-56 p-4 m-4 flex flex-col items-center justify-center rounded-md transition-transform transform hover:scale-105">
            <RiWhatsappFill size={50} color='#10b416' />

            <div className="text-mainColor text-sm font-extrabold text-center tracking-wider my-4">
              WHATSAPP
            </div>

            <div className="text-mainColor text-xs text-center font-semibold tracking-wide">
              +91-7735791829
            </div>
          </div>

          <div className="bg-primaryBgColor h-44 w-56 p-4 m-4 flex flex-col items-center justify-center rounded-md transition-transform transform hover:scale-105">
            <IoMdCall size={50} color='#30aadd' />

            <div className="text-mainColor text-sm font-extrabold text-center tracking-wider my-4">
              PHONE
            </div>

            <div className="text-mainColor text-xs text-center font-semibold tracking-wide">
              +91-7735791829
            </div>
          </div>
        </div>
      </React.Fragment>

      {/* <div>
        <div className="w-full md:mt-4">
          <h4 className="text-center pt-4 font-medium font-sans text-lg md:text-xl tracking-widest text-btnHoverColor">
            LOCATIONS
          </h4>
          <h1 className="text-center pt-3 leading-[16px] md:leading-6 font-serif text-3xl md:text-5xl tracking-wider">
            Our Spa Centers
          </h1>
        </div>

        <div className="h-auto">
          <div className="flex flex-wrap justify-center sm:grid-cols-2">
            <ContactCard
              location="Austin"
              address="22 Texas West Hills"
              timing="Mon - Fri: 9:00 to 19:00"
              mailId="needhelp@company.com"
              phoneNo="888 999 0000 "
            />

            <ContactCard
              location="Boston"
              address="22 Texas West Hills"
              timing="Mon - Fri: 9:00 to 19:00"
              mailId="needhelp@company.com"
              phoneNo="888 999 0000 "
            />

            <ContactCard
              location="New York"
              address="22 Texas West Hills"
              timing="Mon - Fri: 9:00 to 19:00"
              mailId="needhelp@company.com"
              phoneNo="888 999 0000 "
            />

            <ContactCard
              location="Baltimore"
              address="22 Texas West Hills"
              timing="Mon - Fri: 9:00 to 19:00"
              mailId="needhelp@company.com"
              phoneNo="888 999 0000 "
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ContactDesign;
