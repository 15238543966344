import React from "react";
import image1 from "../../Assets/Home/follow1.jpeg";
import image2 from "../../Assets/Home/follow2.jpeg";
import image3 from "../../Assets/Home/follow3.jpeg";
import image4 from "../../Assets/Home/follow4.jpeg";
import image5 from "../../Assets/Home/follow5.jpeg";
import image6 from "../../Assets/Home/follow6.jpeg";

const FollowInInsta = () => {
  const imageUrls = [image1, image3, image4, image5];

  return (
    <div className="flex justify-center items-center gap-4 p-4 flex-wrap">
      {imageUrls.map((url, index) => (
        <div key={index}>
          <img
            className="h-auto max-w-full rounded-lg hover:opacity-40 transition ease-in-out hover:duration-300"
            src={url}
            alt={`Img-${index + 1}`}
          />
        </div>
      ))}
    </div>
  );
};

export default FollowInInsta;
