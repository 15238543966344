import React from 'react'
import About from '../Components/AboutPage/About'

const AboutUs = () => {
  return (
    <React.Fragment>
      <About/>
    </React.Fragment>
  )
}

export default AboutUs
