import React from 'react'
import ContactDesign from '../Components/ContactPage/ContactDesign'

const Contact = () => {
  return (
   <React.Fragment>
        <ContactDesign/>
   </React.Fragment>
  )
}

export default Contact
