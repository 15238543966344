import React from "react";
import whatelseBg from "../../Assets/Home/spa-bg.jpg";
import { AiFillCaretRight } from "react-icons/ai";
import { FaCheckCircle } from "react-icons/fa";

const WhatElse = () => {
  return (
    <div className="bg-cover p-16 lg:py-24 flex flex-col lg:flex-row justify-evenly items-start relative" style={{ backgroundImage: `url(${whatelseBg})` }}>
      <div className="text-textwhite text-center lg:text-left lg:w-1/2 z-10">
        <h4 className="text-2xl text-textwhite lg:text-4xl font-mono font-bold mb-4">
          What else we do?
        </h4>

        <h1 className="text-3xl lg:text-4xl font-bold leading-10 tracking-wider mb-6">
          Get An Incredible Spa Experience with Mellis Spa Center
        </h1>

        <div className="w-full flex md:justify-start justify-center items-center">
          <div className="bg-btnHoverColor animate-ping h-16 w-16 flex justify-center items-center">
            <AiFillCaretRight size={35} />
          </div>
        </div>
      </div>

      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      <div className="flex flex-col lg:flex-row justify-center lg:justify-between lg:w-1/2 space-y-6 lg:space-y-0 lg:space-x-12 relative z-10">
        <ul className="space-y-2 lg:space-y-3">
          <li className="flex text-textwhite font-semibold text-lg lg:text-xl">
            <FaCheckCircle size={18} />&nbsp; Aroma Therapy
          </li>
          <li className="flex text-textwhite font-semibold text-lg lg:text-xl">
            <FaCheckCircle size={18} />&nbsp; Sauna Relax
          </li>
          <li className="flex text-textwhite font-semibold text-lg lg:text-xl">
            <FaCheckCircle size={18} />&nbsp; GeoThermal Spa
          </li>
          <li className="flex text-textwhite font-semibold text-lg lg:text-xl">
            <FaCheckCircle size={18} />&nbsp; Body Massage
          </li>
          <li className="flex text-textwhite font-semibold text-lg lg:text-xl">
            <FaCheckCircle size={18} />&nbsp; Body Wrap
          </li>
          <li className="flex text-textwhite font-semibold text-lg lg:text-xl">
            <FaCheckCircle size={18} />&nbsp; Hand & Feet
          </li>
        </ul>

        <ul className="space-y-2 lg:space-y-3">
          <li className="flex text-textwhite font-semibold text-lg lg:text-xl">
            <FaCheckCircle size={18} />&nbsp; Hydro Therapy
          </li>
          <li className="flex text-textwhite font-semibold text-lg lg:text-xl">
            <FaCheckCircle size={18} />&nbsp; Face Masks
          </li>
          <li className="flex text-textwhite font-semibold text-lg lg:text-xl">
            <FaCheckCircle size={18} />&nbsp; Hot Stone
          </li>
          <li className="flex text-textwhite font-semibold text-lg lg:text-xl">
            <FaCheckCircle size={18} />&nbsp; Spring Water
          </li>
          <li className="flex text-textwhite font-semibold text-lg lg:text-xl">
            <FaCheckCircle size={18} />&nbsp; Manicure
          </li>
        </ul>
      </div>
    </div>
  );
};

export default WhatElse;
