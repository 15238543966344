import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import logoImage from "../Assets/Navbar/kaya-spa-bbsr.png";
import BookNowModal from "../Components/BookNow/BookNowModal";
import Header from "./Header";
import { CgCloseO } from "react-icons/cg";

const Navbar = () => {
  const [bookNowModal, setBookNowModal] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const sidebarClassNames = isNavOpen ? "fixed inset-0 z-40 flex" : "fixed inset-0 z-40 hidden";

  return (
    <React.Fragment>
      <Header />
      <BookNowModal bookNowModal={bookNowModal} setBookNowModal={setBookNowModal} />
      <nav className="bg-white p-4 shadow-md flex items-center justify-between relative">
        {/* Logo */}
        <div className="flex items-center">
          <img src={logoImage} alt="Logo" className="h-20 w-auto" />
        </div>

        {/* Hamburger Menu Icon */}
        <div className="md:hidden">
          <FaBars
            onClick={() => setIsNavOpen(true)}
            className="h-8 w-8" />

          {/* <button onClick={() => setIsNavOpen(!isNavOpen)}>
            {isNavOpen
              ?
              <FaTimes className="h-8 w-8" />
              :
              <FaBars className="h-8 w-8" />}
          </button> */}
        </div>

        {/* Navigation Links */}
        <div className="w-full md:flex hidden justify-center items-center tracking-wider">
          <Navlink to="/" setIsNavOpen={setIsNavOpen}>Home</Navlink>
          <Navlink to="/about" setIsNavOpen={setIsNavOpen}>About</Navlink>
          <Navlink to="/services" setIsNavOpen={setIsNavOpen}>Services</Navlink>
          <Navlink to="/gallery" setIsNavOpen={setIsNavOpen}>Gallery</Navlink>
          <Navlink to="/contact" setIsNavOpen={setIsNavOpen}>Contact</Navlink>
        </div>

        {/* Book Now Button */}
        <div className="hidden md:block">
          <button onClick={() => setBookNowModal(true)} className="bg-mainColor text-white px-4 py-2 rounded-md hover:bg-btnHoverColor tracking-wider">
            Book Now
          </button>
        </div>

        {/* Mobile Sidebar */}
        <div className={sidebarClassNames}>
          <div className="fixed inset-0 bg-black bg-opacity-25"
            onClick={() => setIsNavOpen(false)}
            aria-hidden="true"></div>
          <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
            <div className="px-6 py-5">
              <CgCloseO
                onClick={() => setIsNavOpen(false)}
                size={28}
              />
            </div>

            <nav className="flex-1 h-0 pb-4 overflow-y-auto px-2">
              <Navlink to="/" setIsNavOpen={setIsNavOpen}>Home</Navlink>
              <Navlink to="/about" setIsNavOpen={setIsNavOpen}>About</Navlink>
              <Navlink to="/services" setIsNavOpen={setIsNavOpen}>Services</Navlink>
              <Navlink to="/gallery" setIsNavOpen={setIsNavOpen}>Gallery</Navlink>
              <Navlink to="/contact" setIsNavOpen={setIsNavOpen}>Contact</Navlink>
            </nav>
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

const Navlink = ({ to, children, setIsNavOpen }) => (
  <div className="text-gray-800 font-bold tracking-wider mx-4">
    <NavLink
      to={to}
      className={({ isActive }) => "block px-4 py-2 rounded-md focus:outline-none" + (isActive ? " bg-primaryBgColor text-btnHoverColor" : " hover:text-btnHoverColor")}
      onClick={() => setIsNavOpen(false)} // Close the sidebar when a link is clicked
    >
      {children}
    </NavLink>
  </div>
);

export default Navbar;




// import React, { useState } from "react";
// import logoImage from "../Assets/Navbar/kaya-spa-bbsr.png";
// import { NavLink } from "react-router-dom";
// import BookNowModal from "../Components/BookNow/BookNowModal";
// import Header from "./Header";

// const Navbar = () => {
//   const [bookNowModal, setBookNowModal] = useState(false);
//   return (
//     <React.Fragment>
//       <Header />
//       <BookNowModal
//         bookNowModal={bookNowModal}
//         setBookNowModal={setBookNowModal}
//       />

//       <nav className="bg-white p-4 shadow-lg flex flex-col md:flex-row items-center justify-between">
//         <div className="flex items-center mb-4 md:mb-0">
//           <img
//             src={logoImage}
//             alt="Logo"
//             className="h-20 w-auto mr-2" />
//         </div>

//         <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4 tracking-wider">
//           <Navlink to="/">
//             Home
//           </Navlink>

//           <Navlink to="/about">
//             About
//           </Navlink>

//           <Navlink to="/services">
//             Services
//           </Navlink>

//           <Navlink to="/gallery">
//             Gallery
//           </Navlink>

//           <Navlink to="/contact">
//             Contact
//           </Navlink>
//         </div>

//         <button
//           onClick={() => setBookNowModal(true)}
//           className="bg-mainColor text-white px-4 py-2 mt-4 md:mt-0 rounded-md hover:bg-btnHoverColor tracking-wider"
//         >
//           Book Now
//         </button>
//       </nav>
//     </React.Fragment>
//   );
// };


// const Navlink = ({ to, children }) => (
//   <div className="text-gray-800 font-bold tracking-wider">
//     <NavLink
//       to={to}
//       className={({ isActive }) =>
//         "px-4 py-2 rounded-md focus:outline-none" + (isActive ? " bg-primaryBgColor text-btnHoverColor" : " hover:text-btnHoverColor")
//       }
//     >
//       {children}
//     </NavLink>
//   </div>
// );

// export default Navbar;