import React from "react";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="bg-mainColor pt-8 pb-4 dark:bg-[#27252a]">
        <div className="mx-auto w-full max-w-screen-xl px-4">
          <div className="flex flex-col sm:flex-row justify-between items-center text-center sm:text-left pb-8 space-y-4 sm:space-y-0">
            <div className="flex-1 sm:max-w-xs mx-auto sm:mx-0">
              <h3 className="text-lg tracking-wider font-semibold text-white mb-4">Contact Us</h3>
              <ul>
                <li className="mb-4">
                  <p className="tracking-wide text-gray-300">
                    kayaspabhubaneswar@gmail.com
                  </p>
                </li>
                <li>
                  <p className="tracking-wide text-gray-300">
                    +91-7735791829
                  </p>
                </li>
              </ul>
            </div>

            <div className="flex-1 sm:max-w-xs mx-auto sm:mx-0">
              <h3 className="text-lg tracking-wider font-semibold text-white mb-4">
                Timings
              </h3>
              <ul className="text-gray-300 tracking-wide">
                <li className="mb-2">11.00 AM to 9.00 PM</li>
              </ul>
            </div>

            <div className="flex-1 sm:max-w-xs mx-auto sm:mx-0">
              <h3 className="text-lg tracking-wider font-semibold text-white mb-4">
                Address
              </h3>
              <p className="tracking-wide text-gray-300 mt-4 leading-6">
                Infront of BMC Bhawani Mall,<br /> C/41, Saheed Nagar, Bhubaneswar,<br /> Odisha - 751007
              </p>
            </div>
          </div>

          <div className="border-t border-gray-700 pt-4">
            <div className="flex flex-col sm:flex-row justify-between items-center text-gray-400 text-sm space-y-2 sm:space-y-0">
              <p>
                {new Date().getFullYear()} © <span className="font-bold text-gray-300">
                  Kaya Spa, Bhubaneswar
                </span>.
              </p>
              <p>
                Design & Develop by <a href="https://webbocket.com/" target="_blank" rel="noopener noreferrer" className="underline font-bold hover:text-gray-300">Web_Bocket Pvt. Ltd.</a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
