import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import Gallery from "./Pages/Gallery";
import Services from "./Pages/Services";
import Contact from "./Pages/Contact"
import ErrorPage from "./Pages/ErrorPage";

const App = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout> <Home /> </Layout>} />
          <Route path="/about" element={<Layout> <AboutUs /> </Layout>} />
          <Route path="/contact" element={<Layout> <Contact /> </Layout>} />
          <Route path="/services" element={<Layout> <Services/> </Layout>}/>
          <Route path="/gallery" element={<Layout> <Gallery/> </Layout>}/>

          {/* <Route path="/paymentSummary/:id" element={<PaymentSummary />} />*/}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
