import { Box, Modal } from "@mui/material";
import * as React from "react";
import emailjs from "emailjs-com";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
};

const BookNowModal = (props) => {
  const [loading, setLoading] = React.useState(false);
  const initialState = {
    customerName: "",
    number: "",
    email: "",
    message: ""
  }
  const [formData, setFormData] = React.useState(initialState);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  }

  const clearState = () => setFormData({ ...initialState });
  const form = React.useRef();

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      console.log("Sending email...");
      console.log("data",formData)
      const result = await emailjs.sendForm(
        "service_7d9glud",
        "template_mkuqh7n",
        form.current,
        "hzPWid4jlfK0StVsi"
      );
      if (result?.status === 200) {
        alert("Your message has been delivered");
        setLoading(false);
        clearState();
      }
    } catch (error) {
      console.error("Error sending form:", error);
      setLoading(false);
    }
  };
  return (
    <React.Fragment>
      <Modal
        open={props.bookNowModal}
        onClose={() => props.setBookNowModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="btn-wrapper text-center">
            <div className="text-xl text-[#2b2f32] font-bold">Book Now</div>
          </div>

          <div className="flex-auto mt-2">
            <form ref={form} onSubmit={HandleSubmit}>
              <div className="flex justify-center items-center mb-3 w-96">
                <div className="relative w-full">
                  <div className="text-grayTextColor block text-sm mb-2">
                    Full Name
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    name="customerName"
                    value={formData.customerName}
                    required
                    onChange={handleInputChange}
                    type="text"
                    className="text-grayTextColor border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-full ease-linear transition-all duration-150"
                    placeholder="Enter Your Name"
                  />
                </div>
              </div>
              <div className="flex justify-center items-center mb-3 w-96">
                <div className="relative w-full">
                  <div className="text-grayTextColor block text-sm mb-2">
                    Phone
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="text"
                    name="number"
                    value={formData.number}
                    onChange={handleInputChange}
                    required
                    className="text-grayTextColor border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-44 ease-linear transition-all duration-150"
                    placeholder="Enter Number"
                  />
                </div>

                <div className="relative w-full ml-6">
                  <div className="text-grayTextColor block text-sm mb-2">
                    Email Address
                    <span className="text-red-600 text-lg"> *</span>
                  </div>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    className="text-grayTextColor border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-44 ease-linear transition-all duration-150"
                    placeholder="Enter email"
                  />
                </div>
              </div>

              <div className="relative w-full mb-5">
                <div className="text-grayTextColor block text-sm mb-2">
                  Write a Message
                  <span className="text-red-600 text-lg"> *</span>
                </div>
                <textarea
                  type="text"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                  placeholder="Write a Message"
                  className="text-grayTextColor border-0 px-3 py-3 placeholder-[#718092] bg-white rounded text-sm shadow focus:outline-none focus:ring-1 ring-[#845ff6] w-96 ease-linear transition-all duration-150"
                >
                </textarea>
              </div>

              <div className="w-full flex items-center justify-center mt-5">
                <button className="h-12 w-4/5 flex content-center items-center justify-center text-lg font-semibold text-white bg-mainColor hover:bg-btnHoverColor transition ease-in-out hover:duration-300 rounded-xl shadow-lg shadow-[#ffdcc7]">
                  {0 ? (
                    <div className="w-8 h-8 rounded-full animate-spin border-y-4 border-dashed border-white border-t-transparent"></div>
                  ) : (
                    <span>SEND A MESSAGE</span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default BookNowModal;
